import {
  Filter2,
  TicketStar,
  Discovery,
  Category,
  Danger,
  Setting,
} from "react-iconly";

const components = [
  {
    header: "SETTINGS",
  },
  {
    id: "neighbour",
    title: "Neighbourhood",
    icon: <Category set="curved" className="remix-icon" />,
    children: [
      {
        id: "add-Neighbourhood",
        title: "Add Neighbourhood",
        navLink: "/components/addneighbour",
      },
      {
        id: "List-Neighbourhood",
        title: "List Neighbourhood",
        navLink: "/components/listneighbour",
      },
    ],
  },

  {
    id: "LV-Money",
    title: "LVGold",
    icon: <Setting set="curved" />,
    children: [
      {
        id: "LV-Money",
        title: "LVGold Settings",
        navLink: "/components/lvmoney",
      },
      {
        id: "LV-Buy",
        title: "User Buy LVGOLD",
        navLink: "/components/userbuylvgold",
      },
      {
        id: "LV-Money1",
        title: "Withdraw Request",
        navLink: "/components/lvgoldWithdraw",
      },
      {
        id: "LV-Money2",
        title: "Approve Request",
        navLink: "/components/lvGoldApprove",
      },
    ],
  },
  {
    id: "Category",
    title: "Category",
    icon: <Category set="curved" className="remix-icon" />,
    children: [
      {
        id: "add-category",
        title: "Add Category",
        navLink: "/components/addcategory",
      },
      {
        id: "List-category",
        title: "List Category",
        navLink: "/components/listcategory",
      },
    ],
  },
  {
    id: "TFA",
    title: "2FA",
    icon: <Category set="curved" className="remix-icon" />,
    children: [
      {
        id: "TFA",
        title: "2FA",
        navLink: "/components/tfa",
      },
    ],
  },
];

export default components;
