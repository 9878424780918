import {
    CloseSquare,
    Discount,
    User,
    Discovery,
    Paper,
    InfoSquare,
    Password,
    PaperPlus,
    Unlock,
    Bookmark,
    Message,
    People,
} from "react-iconly";

const pages = [
    {
        header: "PAGES",
    },
    {
        id: "user",
        title: "User",
        icon: <People set="curved"/>,
        children: [
            {
                id: "list-user",
                title: "List User",
                navLink: "/pages/user",
            },
            
        ],
    },
    
];

export default pages