import Web3 from "web3";
import config from "../lib/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { toastAlert } from "../lib/toastAlert";
// import { postWallAddress } from "../actions/users";

export async function getprovider() {
  if (
    localStorage.getItem("walltype") &&
    localStorage.getItem("walltype") != null &&
    localStorage.getItem("walltype") != undefined &&
    localStorage.getItem("walltype") != "" &&
    localStorage.getItem("walltype") == "trust"
  ) {
    var provider = new WalletConnectProvider({
      rpc: {
        [config.livechainid]: config.liverpcurl,
      },
      chainId: config.livechainid,
    });

    provider.on("connect", () => {});

    await provider.enable();
    let web3 = new Web3(provider);
    var network = await web3.eth.net.getId();
    var result = await web3.eth.getAccounts();
    if (config.livechainid == network) {
      var data = {
        provider: provider,
        address: web3.utils.toChecksumAddress(result[0]),
      };
      return data;
    } else {
      // await provider.disconnect();
      let result = await web3.eth.getAccounts();
      let data = {
        provider: window.web3.currentProvider,
        address: web3.utils.toChecksumAddress(result[0]),
      };
      return data;
    }
  } else {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable();
          const web3 = new Web3(window.web3.currentProvider);
          if (window.web3) {
            if (
              window.web3.currentProvider.networkVersion == config.livechainid
            ) {
              var result = await web3.eth.getAccounts();

              var data = {
                provider: window.web3.currentProvider,
                address: web3.utils.toChecksumAddress(result[0]),
              };
              console.log(data, result, "resulttttttt");
              return data;
            } else {
              let result = await web3.eth.getAccounts();
              let data = {
                provider: window.web3.currentProvider,
                address: web3.utils.toChecksumAddress(result[0]),
              };
              // toastAlert("error", "Please connect Polygon network", "wallet");
              console.log(data, "hai");
              return data;
            }
          }
        }
      } catch (err) {
        console.log(err, "erere 12313");
      }
    } else {
      let web3 = new Web3(config.provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      toastAlert("error", "Please Add Metamask Extension", "wallet");
      var data = {
        address: result[0],
        provider: config.provider,
      };
      return data;
    }
  }
}

try {
  window.addEventListener("load", async (event) => {
    event.preventDefault();
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        window.location.reload(true);
        localStorage.setItem("account", accounts[0]);
      });

      window.ethereum.on("networkChanged", async function (networkId) {
        if (networkId == config.livechainid) {
          window.location.reload(true);
          // getprovider();
        } else {
          toastAlert("error", "Please connect Polygon network", "wallet");
          try {
            let web3 = new Web3(window.web3.currentProvider);
            await web3.currentProvider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            let address = await web3.eth.getAccounts();
            localStorage.setItem("account", address[0]);
            toastAlert("success", "Wallet connected 🦊 ", "wallet");
            window.location.reload(false);
            // let payload = {
            //   address:address[0].toLowerCase()
            // }
            // const data = await postWallAddress(payload)
            // localStorage.setItem("Token",data.user.token)
            // console.log(data,'data2');
          } catch (switchError) {
            console.log("some error on switching", switchError);
          }
        }
      });
    }
  });
} catch (e) {
  console.log("error on wallet watch function");
}
