import { lazy } from "react";

const PagesRoutes = [
  //COMPONENTS
  {
    path: "/components/lvmoney",
    component: lazy(() =>
      import("../../view/components/settings/LVMoneysettings")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/lvgoldwithdraw",
    component: lazy(() =>
      import("../../view/components/LVGoldWithdraw/lvgoldWithdraw")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/lvgoldapprove",
    component: lazy(() =>
      import("../../view/components/lvGoldApprove/lvGoldApprove")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/userbuylvgold",
    component: lazy(() => import("../../view/components/UserBuyLv/userBuyLv")),
    layout: "VerticalLayout",
  },
  {
    path: "/components/addcategory",
    component: lazy(() =>
      import("../../view/components/Category/add-category")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/listcategory",
    component: lazy(() =>
      import("../../view/components/Category/list-category")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/addneighbour",
    component: lazy(() =>
      import("../../view/components/neighbour/createneighbour")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/listneighbour",
    component: lazy(() =>
      import("../../view/components/neighbour/listneighbour")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/addcollection",
    component: lazy(() =>
      import("../../view/components/Collection/AddCollection")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/listcollection",
    component: lazy(() =>
      import("../../view/components/Collection/ListCollection")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/components/tfa",
    component: lazy(() => import("../../view/components/tfa/tfa")),
    layout: "VerticalLayout",
  },

  // MAIN

  {
    path: "/main/dashboard/nft",
    component: lazy(() => import("../../view/main/dashboard/analytics")),
    layout: "VerticalLayout",
  },
  {
    path: "/LoginPage/Login",
    component: lazy(() => import("../../view/apps/LoginPage/Login")),
    layout: "FullLayout",
  },

  // APPS
  {
    path: "/apps/create-nft",
    component: lazy(() => import("../../view/apps/createnft/CreateNFT")),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/nftTransactionHistory",
    component: lazy(() =>
      import("../../view/pages/Transactions/nftTransactionHistory")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/lvTransactionHistory",
    component: lazy(() =>
      import("../../view/pages/Transactions/lvTransactionHistory")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/lvRewardTransactionHistory",
    component: lazy(() =>
      import("../../view/pages/Transactions/lvRewardTransactionHistory")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/list-lvassects",
    component: lazy(() => import("../../view/apps/List-NFT/LV_Assets")),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/list-lvland",
    component: lazy(() => import("../../view/apps/List-NFT/LV_Land")),
    layout: "VerticalLayout",
  },
  {
    path: "/apps/addlv-reward",
    component: lazy(() => import("../../view/apps/lvReward/addReward")),
    layout: "VerticalLayout",
  },
  {
    path: "/main/Analytics/LVAnalytics",
    component: lazy(() => import("../../view/main/Analytics/LVAnalytics")),
    layout: "VerticalLayout",
  },
  {
    path: "/main/Analytics/SaleAnalytics",
    component: lazy(() => import("../../view/main/Analytics/SaleAnalytics")),
    layout: "VerticalLayout",
  },

  // PAGES
  {
    path: "/pages/user",
    component: lazy(() => import("../../view/pages/User/user")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/authentication/login",
    component: lazy(() => import("../../view/pages/authentication/login")),
    layout: "FullLayout",
  },
  {
    path: "/pages/authentication/change-password",
    component: lazy(() =>
      import("../../view/pages/authentication/recover-password")
    ),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
