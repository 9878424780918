let key = {};
// process.env.REACT_APP_MODE = "production"
if (process.env.REACT_APP_MODE === "production") {
  // console.log("Set Production Config")
  // const API_URL = 'https://lvmarket.lifeverse.com';//DEMO
  // key = {
  //     baseUrl: `${API_URL}`,
  //     singlesmartContract: "0xDE7E331f0a941CF825465C34f1bE1d803F308690",   //live
  //     network: 80001,
  // };
} else {
  // console.log("Set Development Config");
  // const API_URL = "http://localhost";
  // key = {
  //   baseUrl: `${API_URL}:3040`,
  //   // Back_URL: "http://localhost:3040/",
  //   singlesmartContract: "0xDE7E331f0a941CF825465C34f1bE1d803F308690", //demo
  //   network: 80001,
  //   lvMoneyCont: "0xfDe452abD525135af5F48D75c1cCc1ADe4477691",
  //   liverpcurl: "https://matic-mumbai.chainstacklabs.com",
  //   livechainid: 80001,
  //   networkVersion: "80001",
  //   testScan: "https://mumbai.polygonscan.com/tx",
  // };

  const API_URL = "https://lvmarket.lifeverse.com"; //DEMO
  key = {
    baseUrl: `${API_URL}`,
    singlesmartContract: "0xDE7E331f0a941CF825465C34f1bE1d803F308690", //live
    network: 80001,
    lvMoneyCont: "0xfDe452abD525135af5F48D75c1cCc1ADe4477691",
    liverpcurl: "https://matic-mumbai.chainstacklabs.com",
    livechainid: 80001,
    networkVersion: "80001",
  testScan:"https://mumbai.polygonscan.com/tx"
  };

  // const API_URL = "https://lifeversetestapi.wealwin.com"; //DEMO
  // key = {
  //   baseUrl: `${API_URL}`,
  //   singlesmartContract: "0xDE7E331f0a941CF825465C34f1bE1d803F308690", //live
  //   network: 80001,
  //   lvMoneyCont: "0xfDe452abD525135af5F48D75c1cCc1ADe4477691",
  //   liverpcurl: "https://matic-mumbai.chainstacklabs.com",
  //   livechainid: 80001,
  //   networkVersion: "80001",
  //   testScan: "https://mumbai.polygonscan.com/tx",
  // };
}

export default key;
