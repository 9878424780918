import { Calendar, People, Buy, Plus, Graph } from "react-iconly";


const apps = [
    {
        header: "NFT",
    },
    {
        id: "create-nft",
        title: "Create NFT",
        icon: <Plus set="curved"/>,
        navLink: "/apps/create-nft",
    },
    {
        id: "list-nft",
        title: "List NFT",
        icon: <Graph set="curved" />,

        children:[
            {
                id: "lv-land",
                title: "LVLand",
                navLink: "/apps/list-lvland",
            },
            {
                id: "Lv-Asset",
                title: "LVAssets",
                navLink: "/apps/list-lvassects",
            },

        ]
    },
    {
        id: "reward-add",
        title: "LVReward",
        icon: <People set="curved" />,

        children: [
          {
            id: "add-lvreward",
            title: "Add LVReward",
            navLink: "/apps/addlv-reward",
          },
          // {
          //   id: "edit-lvreward",
          //   title: "Update LVReward",
          //   navLink: "/apps/addlv-reward",
          // },
        ],
      },
    {
        id: "transactions",
        title: "Transaction Details",
        icon: <Buy set="curved"/>,
        // navLink: "/apps/transactionHistory",
        children:[
            {
                id: "nft",
                title: "NFT Transactions",
                navLink: "/apps/nftTransactionHistory",
            },
            {
                id: "lvmoney",
                title: "LVGOLD Transactions",
                navLink: "/apps/lvTransactionHistory",
            },
            {
                id: "lvrewardmoney",
                title: "LVReward Transactions",
                navLink: "/apps/lvRewardTransactionHistory",
            },

        ]
    },

    {
        id: "collection",
        title: "Collection",
        icon: <Graph set="curved" />,

        children: [
          {
            id: "adCollection",
            title: "Add Collection",
            navLink: "/components/addcollection",
          },
          {
            id: "editCollection",
            title: "List Collection",
            navLink: "/components/listcollection",
          },
        ],
      },
    
];

export default apps