import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table, Typography } from "antd";
import { Col, Row } from "antd";
import { Card, Space } from "antd";
import Web3 from "web3";
import Chart from "react-apexcharts";
import lvMoneyAbi from "../../../ABI/LVMoney.json";
import config from "../../../lib/config";
import config1 from "../../../Actions/config";
import { getprovider } from "../../../Actions/provider";
import {
  getlvCirculate,
  getTrsactionsNFT,
  getLVGoldCirc,
} from "../../../Actions/admin";
import moment from "moment";
import "../../../App.css";

export default function LVAnalytics() {
  const { Title, Paragraph, Text, Link } = Typography;
  const [totSub, setTotSub] = useState(0);
  const [cirAmt, setCirAmt] = useState(0);
  const [buyLv, setBuyLv] = useState(0);
  const [lvCirc, setLvCirc] = useState(0);
  const [lvMonth, setLvMonth] = useState(0);
  const [series, setSeries] = useState();
  const [opt, setOpt] = useState();
  const [options, setOptions] = useState();
  const [series1, setSeries1] = useState();
  const [Tras, setTras] = useState();
  const [lvgCir, setLVgCir] = useState();
  const [maticBal, setMaticBal] = useState(0);
  const web3 = new Web3(window.web3.currentProvider);

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n?.toString() || "";
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  // useEffect(()=>{
  //   ethereum.request({
  //     method: 'wallet_addEthereumChain',
  //     params: [{
  //         chainId: web3.utils.toHex('80001'),
  //         chainName: 'Polygon',
  //         nativeCurrency: {
  //             name: 'MATIC',
  //             symbol: 'MATIC',
  //             decimals: 18
  //         },
  //         rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
  //         blockExplorerUrls: ['https://www.polygonscan.com']
  //     }],
  // })
  // .then(() => console.log('network added'))
  // .catch(() => console.log('could not add network'))
  // },[])

  const token = localStorage.getItem("admin_token");
  const history = useHistory();
  if (!token) {
    history.push("/pages/authentication/login");
  }

  useEffect(() => {
    lvCirculate();
  }, [totSub]);

  useEffect(() => {
    getAvaiLv();
    getLvTrasc();
  }, []);

  const lvCirculate = async () => {
    let arrLv = [];
    let arrLvm = [];
    let arrMonm = [];
    let arrMon = [];
    let arrLv1 = [];
    let arrMon1 = [];
    let arrWeek = [];
    let arrWeek1 = [];
    let arrLv2 = [];
    const data = await getlvCirculate();
    console.log(data, "%%%%%%");
    setCirAmt(data?.data?.data[0]?.circulateAmt);
    setBuyLv(data?.data?.data1[0]?.circulateAmt);
    data?.data?.dataChart.map((ele) => {
      arrLv.push(ele.circulateAmt);
      arrMon.push(ele.dayOfMonth + " " + ele.Month + " " + ele._id.Year);
    });
    data?.data?.dataChart1?.map((ele) => {
      arrLvm.push(ele.circulateAmt);
      arrMonm.push(ele.dayOfMonth + " " + ele.Month + " " + ele._id.Year);
    });
    data?.data?.dataChartBar.map((ele) => {
      arrWeek.push(ele.Week);
      arrLv1.push(ele.circulateAmt);
      // arrMon1.push(ele.Month);
    });
    data?.data?.dataChartBar1.map((ele) => {
      arrWeek.push(ele.Week);
      arrLv2.push(ele.circulateAmt);
    });
    setLvCirc(arrLv);
    setLvMonth(arrMon);
    setSeries([
      {
        name: "LVGOLD Circulations",
        data: arrLv.length > 0 ? arrLv : 0,
      },
      // {
      //   name: "LVGOLD Buy via Stripe",
      //   data: arrLvm.length > 0 ? arrLvm : 0,
      // },
    ]);
    setOpt({
      chart: {
        type: "area",
        height: 200,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      title: {
        text: "Circulate of LVGOLD",
        align: "left",
      },
      labels: arrMon,
      xaxis: {
        categories: 1,
      },
      yaxis: {
        opposite: false,
      },
      legend: {
        horizontalAlign: "left",
      },
    });

    setSeries1([
      {
        name: "LVGOLD Circulations",
        data: arrLv1,
      },
      // {
      //   name: "LVGOLD Buy via Stripe",
      //   data: arrLv2,
      // },
    ]);

    if (arrWeek && arrWeek.length > 0) {
      setOptions({
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: arrWeek,
        },
        yaxis: {
          title: {
            text: "LVGOLD Value",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "" + val;
            },
          },
        },
      });
    }
  };

  const getAvaiLv = async () => {
    const web3 = new Web3(window.web3.currentProvider);
    console.log(web3, "ffff");
    let lvMoneyCont = new web3.eth.Contract(lvMoneyAbi, config.lvMoneyCont);
    console.log(lvMoneyCont, "lvMoneyCont");
    let lvmbal = await lvMoneyCont.methods
      .totalSupply()
      .call()
      .then((res) => {
        console.log(res, "res");
        const resdata = parseInt(res);
        const resCo = resdata / 1e18;
        const convt = convert(resCo);
        console.log(typeof buyLv, "convt");
        setTotSub(convt);
      })
      .catch((err) => console.log(err, "gggggg    gggggggg"));
    console.log(lvmbal, "lvmbal");

    web3.eth.getAccounts(async function (err, result) {
      var val = await web3.eth.getBalance(config1.adminAddr); //result[0].toLowerCase()
      var balance = val / 1000000000000000000;
      console.log(balance, "balancebalancebalancebalance");
      setMaticBal(balance);
    });
  };

  useEffect(() => {
    getCirculateLv();
  }, []);

  const getCirculateLv = async () => {
    const data = await getLVGoldCirc();
    console.log(data, "ddfsdfsf");
    setLVgCir(data[0].volumn);
  };

  const getLvTrasc = async () => {
    var res = await getTrsactionsNFT();
    console.log(res, "res");
    // res.map((ele, i) => {
    //   ele.SSno = i + 1;
    // });
    const filter = res.filter((ele) => ele.buyWith == "LVMoney");
    setTras(filter);
  };

  const columns = [
    // {
    //   title: "NFT",
    //   // dataIndex: "nftContract",
    //   // key: "type",
    //   render: (val) => <>{val.nftdetails[0]?.tokenName}</>,
    // },
    {
      title: "Email",
      dataIndex: "email",
      // ...getColumnSearchProps("email"),
      key: "email",
    },

    {
      title: "Assets",
      dataIndex: "nftContract",
      key: "type",
    },
    {
      title: "Value",
      // dataIndex: "transaferAmt",
      key: "Amount",
      render: (val) => (
        <>
          {val.buyWith == "LVReward"
            ? `${convert(val.transaferAmt)} LVReward`
            : `${convert(val.transaferAmt)} LVGOLD`}
        </>
      ),
    },

    {
      title: "Date",
      dataIndex: "createdAt",
      key: "address",
      render: (val) => <> {moment(val).format("MMMM,Do YYYY, hh:mm A")}</>,
    },
  ];
  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  return (
    <div>
      <Row gutter={[32, 0]}>
        <Col flex="1" className="hp-overflow-hidden" span={24}>
          <Row gutter={[32, 32]}>
            <Col md={12} span={12}>
              {/* <Space direction="vertical" size={16}> */}
              <Card title="">
                {/* <p>{totSub} LVMoney</p> */}
                <div style={{ display: "flex" }}>
                  <Card
                    title="Total LVGOLD"
                    style={{
                      width: "90%",
                      marginRight: "10px",
                      marginBottom: "28px",
                    }}
                  >
                    <Paragraph strong>
                      {totSub ? totSub.toFixed(4) : 0} LVGOLD
                    </Paragraph>
                  </Card>
                  <Card
                    title="Admin Balance"
                    style={{ width: "90%", marginBottom: "28px" }}
                  >
                    <Paragraph strong>
                      {maticBal ? maticBal.toFixed(4) : 0} MATIC
                    </Paragraph>
                  </Card>
                </div>
                <div style={{ display: "flex" }}>
                  {/* <Card title="LVGOLD Purchase NFT" style={{width:'90%',marginRight:'10px'}}>
                  <Paragraph strong>{cirAmt ? cirAmt : 0} LVGOLD</Paragraph>
                </Card> */}
                  <Card
                    title="LVGOLD Circulate"
                    style={{ width: "90%", marginRight: "10px" }}
                  >
                    <Paragraph strong>
                      {lvgCir ? lvgCir.toFixed(4) : 0} LVGOLD
                    </Paragraph>
                  </Card>
                  <Card title="LVGOLD Buy via Stripe" style={{ width: "90%" }}>
                    <Paragraph strong>
                      {buyLv ? buyLv.toFixed(4) : 0} LVGOLD
                    </Paragraph>
                  </Card>
                </div>
              </Card>
              {/* </Space> */}
            </Col>
            <Col md={12} span={12}>
              <Card className="hp-border-color-black-40 hp-overflow-hidden">
                <div id="visiters-line-card">
                  {opt && (
                    <>
                      <Chart
                        options={opt}
                        series={series}
                        type="area"
                        height={300}
                        legend="legend"
                      />
                    </>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[32, 0]}>
        <Col flex="1" className="hp-overflow-hidden" span={24}>
          <Row
            gutter={[32, 32]}
            style={{ marginTop: "20px", marginBottom: "30px" }}
          >
            <Col md={12} span={12}>
              {/* <Space direction="vertical" size={16}> */}
              <Card>
                {options && (
                  <>
                    <Chart
                      options={options && options}
                      series={series1}
                      type="bar"
                      height={350}
                    />
                  </>
                )}
              </Card>
            </Col>

            {/* <Col md={12} span={12}>
              <Card>
              <Card title="Admin Balance" style={{width:'90%'}}>
                  <Paragraph strong>{maticBal ? maticBal.toFixed(4) : 0} LVGOLD</Paragraph>
                </Card>
              </Card>
            </Col> */}

            <Col md={12} span={12}>
              {/* <Space direction="vertical" size={16}> */}
              <Card title="Transaction LVGOLD">
                {/* <Title level={3}></Title> */}
                <Table columns={columns} dataSource={Tras} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
