import {
  Filter2,
  TicketStar,
  Discovery,
  Category,
  Danger,
  Setting,
} from "react-iconly";

const components = [
  {
    header: "SETTINGS",

    subMenu: [
      {
        id: "neighbour",
        title: "Neighbourhood",
        icon: <Category set="curved" className="remix-icon" />,
        children: [
          {
            id: "add-Neighbourhood",
            title: "Add Neighbourhood",
            navLink: "/components/addneighbour",
          },
          {
            id: "List-Neighbourhood",
            title: "List Neighbourhood",
            navLink: "/components/listneighbour",
          },
        ],
      },
      {
        id: "LV-Money",
        title: "LVGold",
        icon: <Setting set="curved" />,
        children: [
          {
            id: "LV-Money",
            title: "LVGold Settings",
            navLink: "/components/lvmoney",
          },
        ],
      },
      {
        id: "Category",
        title: "Category",
        icon: <Category set="curved" className="remix-icon" />,
        children: [
          {
            id: "add-category",
            title: "Add Category",
            navLink: "/components/addcategory",
          },
          {
            id: "List-category",
            title: "List Category",
            navLink: "/components/listcategory",
          },
        ],
      },
    ],
  },
];

export default components;
