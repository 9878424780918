import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';
import { decodeJwt } from "./Actions/jsonwebtoken";
import Router from "./router/Router";
import store from './store';
import { ToastContainer } from 'react-toastify';

export default function App() {
  const customise = useSelector(state => state.customise)

  // const { isAuth } = store.getState().currentUser;

  // useEffect(() => {
  //   if (isAuth != true && localStorage.admin_token) {
  //     decodeJwt(localStorage.admin_token, store.dispatch);
  //   }
  // }, []);

  return (
    // <ConfigProvider direction={customise.direction}>
    //   <Router />
    // </ConfigProvider>
    <ConfigProvider >
      <ToastContainer/>
       <Router />
     </ConfigProvider>
  );
}