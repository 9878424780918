module.exports = {
  Chainid: 80001,
  // marketContract: "0x1A8A70D59C140AE02336dE7f1B0840a2b26e25Ff",
  // marketContract:"0xDE7E331f0a941CF825465C34f1bE1d803F308690",
  LVLANDcontract: "0x16dcb7da79eecbed4c9baa8f1bb3a7c4d6887b24",
  LVASSETScontract: "0x9e1cba7fecafcc90bf6e911ce1fecdd882dadab1",
  LVASSETScontractMulti: "0x644eb8e7f6bed83a96c9b181e23b165d6ce6197d",
  LVGOLDContract: "0xfde452abd525135af5f48d75c1ccc1ade4477691",
  IPFS_IMG: "https://ipfs.io/ipfs",
  adminAddr: "0x88cFE233c4cC67093E62Ebda6Af68B73F533DBC2",
  // adminAddr: "0x78F708110fc226faBCF14B68A675DbBDa8d090dB",

};
