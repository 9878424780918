import { Home, Graph, Document } from "react-iconly";

const main = [
  // {
  //     header: "Dashboards",
  //     subMenu: [
  //         {
  //             id: "dashboard",
  //             title: "Dashboards",
  //             icon: <Home set="curved" className="remix-icon" />,
  //             children: [
  //                 {
  //                         id: "dashboard",
  //                         title: "Dashboard",
  //                         navLink: "/",
  //                     },

  //             ],
  //         },
  //     ]
  // },
  {
    header: "Analytics",
  },
  {
    id: "analytics",
    title: "Analytics",
    icon: <Home set="curved" className="remix-icon" />,
    children: [
      {
        id: "analytics",
        title: "LVAnalytics",
        navLink: "/main/Analytics/LVAnalytics",
      },
      {
        id: "analytics",
        title: "SaleAnalytics",
        navLink: "/main/Analytics/SaleAnalytics",
      },
    ],
  },
];

export default main;
