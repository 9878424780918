import axios from "axios";

// import action
import { decodeJwt } from "./jsonwebtoken";

// import lib
import config from "../lib/config";
import setAuthToken from "../lib/setAuthToken";

import { SET_CURRENT_USER } from "../constant";

//LoginAction

export const login = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/login`,
      data,
    });
    console.log(respData, "RespDatatatattatattatatattatatatatata");
    localStorage.setItem("admin_token", respData.data.token);
    setAuthToken(respData.data.token);
    decodeJwt(respData.data.token, dispatch);
    console.log(respData, "respData");
    return {
      loading: true,
      result: respData,
    };
  } catch (err) {
    console.log(err, "respData");
    return {
      loading: false,
      errors: err,
    };
  }
};

export const changePass = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/changePass`,
      data,
    });
    return {
      loading: true,
      result: respData.data.message,
    };
  } catch (err) {
    return {
      loading: false,
      result: err.data.message,
      errors: err,
    };
  }
};

export const changepassword = async (data, dispatch) => {
  try {
    console.log(data, "dataa");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/changePassward`,
      data,
    });
    //
    // localStorage.setItem("admin_token", respData.data.token);
    // setAuthToken(respData.data.token);
    // decodeJwt(respData.data.token, dispatch);

    return {
      loading: true,
      result: respData.data.result,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const checkOtp = async (data) => {
  // console.log(data, "datadatadata");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/checkOpt`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    // console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

//AdminProfile

export const getadminDetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/adminDetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getwithdrawReq = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getwithdrawReq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log(respData, "checkingedffefevfewfedsfve");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getweb3Data = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getweb3Data`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log(respData, "checkingedffefevfewfedsfve");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getUserBuyLv = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getUserBuyLv`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getApprReq = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getApprReq`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    console.log(respData, "checkingedffefevfewfedsfve");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const burnLVGold = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/burnLVGold`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    console.log(respData, "dafcdcfdascfsad");
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.result,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateProfile = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateAdminProfile`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateNftStatus = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateNftStatus`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updateNftStatuslvs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateNftStatuslvs`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const nftListByFixed = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nftListByFixed`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const nftListByTimed = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nftListByTimed`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const nftListByReserved = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nftListByReserved`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const nftListByReservation = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nftListByReservation`,
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const getNftlist = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNftlist`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};

export const getNftlist2 = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNftlist2`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};

export const getNftsChar = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNftsChar`,
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};
export const getNftsChar2 = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getNftsChar2`,
      data,
    });
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};

export const getStatusNft = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getStatusNft`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};

export const logout = (history) => {
  localStorage.removeItem("admin_token");
  window.location.href = "/Login";
  setAuthToken("");
};

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const ipfsmetadatafunciton = async (data) => {
  // console.log(payload,'payloaddddddddddddddddddddddddd');
  // var formData = new FormData();
  // if (payload.image) {
  //     formData.append("image", payload.image);
  // }
  // if (payload.name) {
  //     formData.append("name", payload.name);
  // }
  // if (payload.tokenId) {
  //     formData.append("tokenId", payload.tokenId);
  // }
  // if (payload.Owner) {
  //     formData.append("Owner", payload.Owner);
  // }
  // if (payload.Creator) {
  //     formData.append("Creator", payload.Creator);
  // }
  // if (payload.Royalities) {
  //     formData.append("Royalities", payload.Royalities);
  // }
  // if (payload.nonce) {
  //     formData.append("nonce", payload.nonce);
  // }
  // if (payload.Signature) {
  //     formData.append("Signature", payload.Signature);
  // }
  // console.log(formData, 'formDataformDataformDataformDataformDataformDataformDataformData');

  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/ipfsmetadata`,
      data,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const ipfsImageHashGet = async (payload) => {
  console.log(payload, "lll");

  var formData = new FormData();
  if (payload.Image) {
    console.log(payload.Image, "summa");
    formData.append("Image", payload.Image);
  }
  console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/ipfsImageHashGet`,
      data: formData,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const createNft = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/createNft`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const updatemultimg = async (data) => {
  try {
    var formData = new FormData();

    for (var i = 0; i < data.image.length; i++) {
      formData.append("image", data.image[i]);
    }

    formData.append("id", data.id);

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatemultimg`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data: formData,
    });
    //    console.log(respData, 'chghghecking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};
export const getuserdetails = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getuserdetails`,
      headers: {
        Authorization: localStorage.admin_token,
      },
    });
    // console.log(respData, 'checking ResponseData');
    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response?.status,
      message: err.response?.message,
      error: err.response?.error,
    };
  }
};
export const SetLEVtokenprice = async (value) => {
  console.log(value);
  try {
    const Tokendata = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/setlevtokenPrice`,
      data: { value },
    });
    console.log(Tokendata.data);
    return Tokendata.data.message;
  } catch (error) {
    console.log(error);
  }
};

export const GetLVEPrice = async () => {
  try {
    const LVEPrice = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getlevtokenPrice`,
    });
    console.log(LVEPrice);
    return LVEPrice.data.result;
  } catch (error) {
    console.log(error);
  }
};
export const getTrsactionsNFT = async () => {
  try {
    const LVEPrice = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getTrsactionsNFT`,
    });
    return LVEPrice.data.result;
  } catch (error) {
    console.log(error);
  }
};

export const getLVGoldCirc = async () => {
  try {
    const lvCirc = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getLVGoldCirc`,
    });
    return lvCirc.data.result;
  } catch (error) {
    console.log(error);
  }
};

export const getTrsactionsLV = async () => {
  try {
    const LVEPrice = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getTrsactionsLV`,
    });
    return LVEPrice.data.result;
  } catch (error) {
    console.log(error);
  }
};
export const getTrsactionsLVRe = async () => {
  try {
    const LVEPrice = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getTrsactionsLVRe`,
    });
    return LVEPrice.data.result;
  } catch (error) {
    console.log(error);
  }
};

export const addCategory = async (payload) => {
  console.log(payload, "lll");

  var formData = new FormData();
  if (payload.Image) {
    console.log(payload.Image, "summa");
    formData.append("assets", payload.Image);
    formData.append("category", payload.category);
  }
  console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addcategory`,
      data: formData,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const addCollection = async (payload) => {
  console.log(payload, "lll");

  var formData = new FormData();
  if (payload.Image) {
    formData.append("assets", payload.Image);
    formData.append("category", payload.category);
  }
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addcollection`,
      data: formData,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};

export const addneighbour = async (payload) => {
  console.log(payload, "lll");

  var formData = new FormData();
  if (payload.Image) {
    formData.append("assets", payload.Image);
    formData.append("category", payload.category);
  }
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/addneighbour`,
      data: formData,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const FetchCategory = async () => {
  try {
    const category = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/fetchcategory`,
    });
    console.log((await category).data.result.length);
    return category;
  } catch (error) {
    console.log(error);
  }
};

export const FetchCollection = async () => {
  try {
    const category = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/fetchcollection`,
    });
    console.log((await category).data.result.length);
    return category;
  } catch (error) {
    console.log(error);
  }
};

export const getneighbour = async () => {
  try {
    const category = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getneighbour`,
    });

    return category;
  } catch (error) {
    console.log(error);
  }
};

export const getApproveData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getApproveData`,
      data,
    });

    return {
      loading: true,
      status: respData.data.status,
      message: respData.data.message,
      result: respData.data.Data,
      callData: respData.data.calldata,
      error: respData.data.error,
    };
  } catch (err) {
    return {
      loading: false,
      status: err.response.data.status,
      message: err.response.data.message,
      error: err.response.data.error,
    };
  }
};

export const FetchOneCategory = async (id) => {
  try {
    console.log(id);
    const res = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/fetchonecategory/${id}`,
    });
    console.log(res);
    if (res.data.result) {
      console.log(res.data.result);
      return res;
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateCategory = async (data) => {
  console.log(data, "filename:");
  const formData = new FormData();
  if (data.Image != undefined) {
    formData.append("assets", data.Image);
    formData.append("category", data.category);
    formData.append("id", data.id);
  }
  try {
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updatecategory`,
      data: data.Image != undefined ? formData : data,
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};
export const updateCollection = async (data) => {
  const formData = new FormData();
  if (data.Image != undefined) {
    formData.append("assets", data.Image);
    formData.append("category", data.category);
    formData.append("id", data.id);
  }
  try {
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateCollection`,
      data: data.Image != undefined ? formData : data,
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateneighbour = async (data) => {
  console.log(data, "filename:");
  const formData = new FormData();
  if (data.Image != undefined) {
    formData.append("assets", data.Image);
    formData.append("category", data.category);
    formData.append("id", data.id);
  }
  try {
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/updateneighbour`,
      data: data.Image != undefined ? formData : data,
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};
export const DeleteCategory = async (id) => {
  try {
    console.log(id);
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deletecategory`,
      data: { id },
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteCollection = async (id) => {
  try {
    console.log(id);
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/deletecollection`,
      data: { id },
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};

export const Deleteneighbour = async (id) => {
  try {
    console.log(id);
    const respon = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/Deleteneighbour`,
      data: { id },
    });
    if (respon) {
      return respon;
    }
  } catch (error) {
    console.log(error);
  }
};
export const LVRewardNew = async (data) => {
  console.log(data, "lll");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/lvrewardAdd`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      error: err.response.data,
    };
  }
};
export const ZeroReward = async (data) => {
  console.log(data, "lll");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/zeroreward`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  }
};
export const getAdmUser = async (data) => {
  console.log(data, "lll");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/getAdmUser`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  }
};
export const otpSendReq = async (data) => {
  console.log(data, "lll");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/otpSendReq`,
      data,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  }
};

export const getlvCirculate = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/getlvCirculate`,
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data,
    };
  } catch (err) {
    return {
      data: respData.data,
    };
  }
};

///

export const get2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/adminapi/routesAdmin/get2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};

export const update2faCode = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/update2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const disable2faCode = async (data) => {
  console.log(data, "j");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/diabled2faCode`,
      headers: {
        Authorization: localStorage.admin_token,
      },
      data,
    });

    return {
      loading: false,
      result: respData,
    };
  } catch (err) {
    var sendErr = "";
    if (err) {
      sendErr = err;
      if (err.response) {
        sendErr = err.response;
        if (err.response.data) {
          sendErr = err.response.data;
          if (err.response.data.errors) {
            sendErr = err.response.data.errors;
          }
        }
      }
    }
    return {
      loading: false,
      error: sendErr,
    };
  }
};
export const CreateTokenValidationAction = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/nameValidation`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const ItemValidation = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/ItemValidation`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const NeighbourhoodNamecheck = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/NeighbourhoodNamecheck`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const collNamecheck = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/collNamecheck`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err);
  }
};
export const catNamecheck = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/adminapi/routesAdmin/catNamecheck`,
      data: payload,
    });
    return {
      data: respData.data,
    };
  } catch (err) {
    console.log(err);
  }
};
